.number-input {
    display: flex;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
}

.number-input input:hover {
    border-left-width: 1px;
    border-right-width: 1px;
}

.number-input button svg:hover {
    color: #fff;
    fill: #fff;
}

.number-input input {
    width: 65px;
    border-radius: 0;
    text-align: center;
    border-left-width: 0;
    border-right-width: 0;
}

.number-input .button {
    width: 40px;
    padding: 4px 6px;
}

.number-input .button {
    background: @primary-button-color;
    background-size: 200% auto;
}

.number-input .button:active,
.number-input .button:focus,
.number-input .button:hover {
    background-position: 100%;
}

.number-input .button .anticon {
    color: @quantity-input-color;
}

.number-input .increase {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.number-input .decrease {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.number-input .ant-btn[disabled].decrease {
    background: #aaaaaa;
}

.number-input .ant-btn .anticon.anticon-minus > svg,
.number-input .ant-btn .anticon.anticon-plus > svg {
    shape-rendering: geometricprecision;
}
