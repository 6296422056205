.mobile-slider .slick-list {
    /* make slider align to the left */
    padding-left: 0 !important;
}

.slick-dots {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 5px;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li,
.slick-dots li button {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
}

.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
}

.slick-dots li button:before {
    font-family: slick;
    font-size: 40px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '\2022';
    text-align: center;
    opacity: 0.25;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-track {
    float: left;
}

:not(#top-banner):not(#featured-area-1):not(#featured-area-2) > .slick-slider .slick-slide > div {
    margin: 50px 30px;
}

@media screen and (max-width: 480px) {
    :not(#top-banner):not(#featured-area-1):not(#featured-area-2)
        > .slick-slider
        .slick-slide
        > div {
        margin: 30px 10px;
    }
}
