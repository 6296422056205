.add-to-wishList svg {
    position: absolute;
    font-size: 41px;
    height: 41px;
    width: 41px;
}

div.wishList > svg {
    color: @wishlist-button-color;
    fill: @wishlist-button-color;
    cursor: pointer;
}

.wishList-featured {
    display: inline-block;
    margin-left: 7px;
    position: absolute;
}

.productPage.wishList svg {
    font-size: 45px;
    height: 45px;
    width: 45px;
}

.productPage svg:hover,
.content-wrapper svg:hover,
.content-wrapper-phone svg:hover {
    color: @primary-color;
    fill: @primary-color;
}

.content-wrapper svg {
    font-size: 62px;
    height: 62px;
    width: 62px;
}

.content-wrapper-phone svg {
    font-size: 55px;
    height: 55px;
    width: 55px;
    line-height: 61px;
}
