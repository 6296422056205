.add-to-wishList svg {
  position: absolute;
  font-size: 41px;
  height: 41px;
  width: 41px;
}
div.wishList > svg {
  color: #E60000;
  fill: #E60000;
  cursor: pointer;
}
.wishList-featured {
  display: inline-block;
  margin-left: 7px;
  position: absolute;
}
.productPage.wishList svg {
  font-size: 45px;
  height: 45px;
  width: 45px;
}
.productPage svg:hover,
.content-wrapper svg:hover,
.content-wrapper-phone svg:hover {
  color: #E60000;
  fill: #E60000;
}
.content-wrapper svg {
  font-size: 62px;
  height: 62px;
  width: 62px;
}
.content-wrapper-phone svg {
  font-size: 55px;
  height: 55px;
  width: 55px;
  line-height: 61px;
}
.number-input {
  display: flex;
  height: 32px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}
.number-input input:hover {
  border-left-width: 1px;
  border-right-width: 1px;
}
.number-input button svg:hover {
  color: #fff;
  fill: #fff;
}
.number-input input {
  width: 65px;
  border-radius: 0;
  text-align: center;
  border-left-width: 0;
  border-right-width: 0;
}
.number-input .button {
  width: 40px;
  padding: 4px 6px;
}
.number-input .button {
  background: #E60000;
  background-size: 200% auto;
}
.number-input .button:active,
.number-input .button:focus,
.number-input .button:hover {
  background-position: 100%;
}
.number-input .button .anticon {
  color: #FFF;
}
.number-input .increase {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.number-input .decrease {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.number-input .ant-btn[disabled].decrease {
  background: #aaaaaa;
}
.number-input .ant-btn .anticon.anticon-minus > svg,
.number-input .ant-btn .anticon.anticon-plus > svg {
  shape-rendering: geometricprecision;
}
.mobile-slider .slick-list {
  /* make slider align to the left */
  padding-left: 0 !important;
}
.slick-dots {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li,
.slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}
.slick-dots li button:before {
  font-family: slick;
  font-size: 40px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '\2022';
  text-align: center;
  opacity: 0.25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-track {
  float: left;
}
:not(#top-banner):not(#featured-area-1):not(#featured-area-2) > .slick-slider .slick-slide > div {
  margin: 50px 30px;
}
@media screen and (max-width: 480px) {
  :not(#top-banner):not(#featured-area-1):not(#featured-area-2) > .slick-slider .slick-slide > div {
    margin: 30px 10px;
  }
}
/* COMMON */
body {
  overflow-x: hidden;
}
table .odd {
  background-color: #E600000F;
}
a {
  text-decoration: underline transparent;
  transition: text-decoration 0.3s ease, color 0.3s ease;
}
a:hover {
  text-decoration: underline;
}
body #hubspot-messages-iframe-container {
  z-index: 900;
}
#content {
  min-height: 80vh;
}
.MuiSvgIcon-root,
.material-icons {
  transition: color 0.3s;
  cursor: pointer;
}
:not(.anticon) > svg,
:not(.ant-btn) > .material-icons {
  color: #E60000;
  fill: #E60000;
}
:not(.ant-btn) > .anticon > svg:hover,
.top-bar-menu-item:hover > a > svg,
:not(.ant-btn) > .material-icons:hover {
  color: #FF0000;
  fill: #FF0000;
}
.material-icons.white {
  color: white;
  fill: white;
}
.anticon {
  transition: color 0.3s;
}
:not(.ant-btn):not(.disabled) > .anticon:hover,
.ant-btn-link:focus,
button.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: #FF0000;
}
.center-spinner {
  position: absolute;
  width: 120px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
:not(.ant-btn) > .anticon {
  color: #E60000;
}
.form-buttons {
  text-align: right;
}
/* ERROR PAGE */
.centered {
  display: table;
  margin: 0 auto;
  text-align: center;
}
/* COMMON END */
/* HEADER */
#page-container {
  padding-top: 55px;
  flex: 1 0 auto;
}
@media screen and (max-width: 1100px) {
  #page-container {
    padding-top: 60px;
    /* Height of header-container */
  }
}
@media screen and (max-width: 725px) {
  #page-container {
    padding-top: 90px;
    /* Height of header-container */
  }
}
/* HEADER END */
/* FOOTER */
#kkfooter {
  line-height: 1.6em;
  clear: left;
  flex-shrink: 0;
  font-size: 18px;
  margin-top: 10px;
}
#kkfooter-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 40px;
}
#tolvutek-mailing-list {
  position: absolute;
  width: 440px;
  background-color: black;
  color: white;
  padding: 20px;
  z-index: 4;
}
#tolvutek-mailing-list .text {
  margin-bottom: 10px;
}
#tolvutek-contact-footer {
  display: flex;
}
#tolvutek-mailing-list-wrapper {
  position: relative;
  width: 440px;
  margin-top: 23px;
}
#tolvutek-mailing-list-image img {
  width: 200px;
  position: absolute;
  right: 0;
  top: -220px;
  z-index: 1;
}
#tolvutek-register-mailing-list {
  position: relative;
  margin-top: 12px;
  height: 40px;
}
#tolvutek-register-mailing-list input {
  height: 40px;
  padding: 15px;
  margin: 0;
  border: 0;
  top: 0;
  position: absolute;
  width: 270px;
  background-color: #4d4d4d;
  color: white;
  border-radius: 30px 0 0 30px;
}
#tolvutek-register-mailing-list input::placeholder {
  color: white;
}
#tolvutek-register-mailing-list a {
  border-radius: 0 30px 30px 0;
  height: 40px;
  width: 130px;
  padding: 0;
  position: absolute;
  left: 270px;
  line-height: 30px;
  color: white;
}
#terms-error {
  color: #E60000;
  font-size: 11px;
}
#terms-success {
  font-size: 11px;
}
#terms-wrapper {
  display: table-row;
}
#terms-wrapper span {
  display: table-cell;
  vertical-align: middle;
}
#terms-wrapper label {
  display: table-cell;
  font-size: 14px;
  line-height: 15px;
  vertical-align: middle;
}
#tolvutek-register-mailing-list a img {
  display: inline-block;
  height: 20px;
  vertical-align: middle;
}
#tolvutek-contact-footer .contact-chat {
  width: 45%;
  text-align: center;
}
#tolvutek-contact-footer .ant-btn {
  color: white;
}
.tolvutek-footer #kkfooter-wrapper {
  background-color: #f1f1f1;
}
#tolvutek-contact-footer .ant-btn {
  width: 120px;
  margin: auto;
  line-height: 20px;
}
#tolvutek-customer-service {
  min-height: 500px;
}
.footer-address {
  margin-bottom: 1em;
}
.footer-icon {
  width: 30px;
  vertical-align: middle;
  padding-right: 5px;
  margin-top: -2px;
}
.footer-area {
  display: inline-block;
  margin-right: 7%;
  vertical-align: top;
  margin-bottom: 2em;
  min-height: 400px;
}
#kkfooter .last-child {
  margin-right: 0;
}
#kkfooter .narrow {
  width: 14.6666667%;
}
#kkfooter .wide {
  width: 31.3333333%;
}
#kkfooter #right-column {
  float: right;
  text-align: right;
  width: 50%;
}
.address-column {
  width: auto;
  text-align: left;
  min-width: 250px;
}
.footer-area h2 {
  margin-top: 0;
  font-size: 20px;
  font-weight: bold;
}
.tolvutek-footer .footer-area h2 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
#copyright-container {
  width: 90%;
  margin: auto;
}
.icon-icons_facebook-logo:before {
  content: '\e918';
}
.icon-icons_twitter-logo:before {
  content: '\e919';
}
.icon-icons_linkedin-logo:before {
  content: '\e91b';
}
.icon-icons_youtube-logo:before {
  content: '\e91c';
}
.icon-icons_instagram-logo:before {
  content: '\e91a';
}
#social-icons {
  display: block;
  text-align: center;
}
#social-icons ul li a span:hover {
  background-color: #FF0000;
}
#social-icons ul li a span {
  font-weight: 400;
  background-color: #E60000;
  color: #fff;
  font-size: 40px;
  border-radius: 50%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
[class*=' icon-'],
[class^='icon-'] {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#social-icons ul {
  list-style: none;
}
#social-icons ul li {
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
}
#mailing-list-link {
  margin-top: 40px;
}
#kkfooter a {
  display: block;
  padding-bottom: 5px;
  padding-top: 5px;
  max-width: 290px;
}
#kkfooter {
  min-height: 200px;
}
#addresses {
  width: 70%;
}
#links {
  text-align: left;
  margin-right: 0;
}
#social-icons {
  padding: 15px 0;
  margin-right: 20px;
}
@media screen and (max-width: 1400px) {
  #kkfooter-wrapper {
    display: block;
  }
  #kkfooter a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #tolvutek-register-mailing-list a {
    padding-top: 5px;
    left: 65%;
  }
  #tolvutek-contact-footer a {
    padding-top: 5px;
  }
}
/* FOOTER END */
/* MAIN PAGE */
.product-gallery-spinner,
.featured-area-spinner {
  min-height: 500px;
  position: relative;
}
#slideshow {
  position: relative;
  margin-top: 15px;
}
#banner-overlay {
  top: 150px;
  left: 200px;
  position: absolute;
  max-width: 500px;
  margin-right: 10px;
}
#banner-overlay h2 {
  font-size: 55px;
  line-height: 1;
}
#banner-overlay h2,
#banner-overlay p {
  color: white;
}
#banner-overlay p {
  font-size: 22px;
}
#banner-overlay .origo-button {
  display: block;
  padding-left: 40px;
  padding-right: 40px;
  border-color: transparent;
  align-content: center;
  line-height: 56px;
}
@media screen and (max-width: 600px) {
  #banner-overlay .origo-button {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 40px;
    height: 40px;
    font-size: 16px;
  }
  #tolvutek-mailing-list-wrapper,
  #tolvutek-mailing-list {
    width: 100%;
  }
  #tolvutek-register-mailing-list input {
    width: 50%;
  }
  #tolvutek-register-mailing-list a {
    left: 50%;
  }
  #tolvutek-customer-service {
    width: 100%;
  }
  #tolvutek-mailing-list {
    height: 300px;
  }
  #tolvutek-mailing-list-image img {
    width: 170px;
    top: -196px;
  }
  #tolvutek-contact-footer .contact-chat {
    width: 60%;
  }
  #kkfooter-wrapper {
    padding: 10px;
  }
}
@media screen and (max-width: 1600px) {
  #banner-overlay {
    top: 100px;
  }
  #banner-overlay h2 {
    font-size: 50px;
  }
  #banner-overlay p {
    font-size: 20px;
  }
}
@media screen and (max-width: 1000px) {
  #banner-overlay {
    top: 50px;
  }
  #kkfooter {
    margin-left: 2%;
    margin-right: 2%;
  }
}
@media screen and (max-width: 1400px) {
  #banner-overlay {
    left: 20px;
  }
  #banner-overlay h2 {
    font-size: 35px;
  }
}
@media screen and (max-width: 480px) {
  #banner-overlay h2 {
    font-size: 18px;
    line-height: 1;
  }
  #banner-overlay p {
    font-size: 14px;
  }
  #banner-overlay .origo-button {
    height: 30px;
    font-size: 15px;
    line-height: 26px;
  }
  #banner-overlay {
    top: 65px;
  }
}
/* MAIN PAGE END */
#breadcrumbs {
  border-bottom: #d9d9d9 1px solid;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}
#page.default #content,
#page.productDetail #content {
  margin-top: 30px;
}
.material-icons.inactive {
  color: #d9d9d9;
}
.ant-picker-input > input,
.ant-picker-header-view > button {
  font-family: TTNorms-Pro-Regular;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-inline-start: 8px;
}
.ant-btn .ant-btn-loading-icon {
  margin-right: 20px;
}
